import React from 'react'
import Img from 'gatsby-image'
import { Box, Flex, Heading, Text } from 'rebass'
import { Sticky, StickyContainer } from 'react-sticky'
import Media from 'react-media'
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link'
import posed from 'react-pose'
import { graphql } from 'gatsby'
import NextProjectHeading from '../components/next-project-heading'

// For now, let's use this as a magic number that describes how long our transition should take
const TRANSITION_LENGTH = 1.5

// Transition to {opacity: 0} when animate === 'exiting'.
const FadingContent = posed.div({
  exiting: { opacity: 0 },
})

const SlidingHeader = posed.div({
  exiting: {
    y: ({ element }) => {
      if (window) {
        // When scrolling back to the top, how far should we actually go?
        // Let's factor the height of our site's header into the equation.
        const candidates = document.getElementsByClassName('header')

        const navbar = candidates[0]
        const navbarDimensions = navbar.getBoundingClientRect()
        const distanceToTop =
          element.getBoundingClientRect().top - navbarDimensions.height

        // And return that aggregate distance as the dynamic "y" value.
        return distanceToTop * -1
      }
    },
    transition: {
      ease: [0.59, 0.01, 0.28, 1], // Make the transition smoother
      delay: 250, // Let's wait a tick before starting
      duration: TRANSITION_LENGTH * 1000 - 250, // And let's be sure not to exceed the 1.5s we have allotted for the entire animation.
    },
  },
})

const FadingNextProjectHeading = posed.div({
  exiting: { opacity: 0 },
})

const Header = props => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    mb={[4, 4, 4, 5]}
  >
    <Text
      as="span"
      mb={2}
      fontFamily="subtitle"
      color="fdgBlack"
      css={{
        textTransform: 'uppercase',
        letterSpacing: '1px',
      }}
    >
      {props.category}
    </Text>
    <Heading
      as="h1"
      mb={2}
      fontFamily="headline"
      textAlign="center"
      color="fdgBlack"
    >
      {props.name}
    </Heading>
    <Text as="time" fontFamily="subtitle" color="greyDarkest">
      {props.date}
    </Text>
  </Flex>
)

const ProjectInner = ({ project, transitionStatus }) => {
  const exitTransition = {
    length: TRANSITION_LENGTH,
    trigger: () => {
      if (document) {
        document.body.style.overflow = 'hidden'
      }
    },
  }

  const entryTransition = {
    delay: TRANSITION_LENGTH,
    trigger: () => {
      if (document && window) {
        window.scrollTo(0, 0)
        document.body.style.overflow = 'visible'
      }
    },
  }

  return (
    <>
      <FadingContent pose={transitionStatus}>
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <>
                <Header
                  name={project.name}
                  category={project.tags[0]}
                  date={project.date}
                />

                <StickyContainer>
                  <Box
                    css={{
                      display: 'grid',
                      gridTemplateColumns: '4fr 3fr',
                      gridColumnGap: 24,
                      paddingBottom: 64,
                    }}
                  >
                    <Box
                      css={{
                        display: 'grid',
                        gridAutoFlow: 'row',
                        gridRowGap: 24,
                      }}
                    >
                      {project.gallery}
                    </Box>

                    <Sticky>
                      {({ style }) => (
                        <Box style={style}>
                          <Text
                            width="100%"
                            maxWidth="40ch"
                            fontFamily="body"
                            dangerouslySetInnerHTML={{
                              __html: project.description,
                            }}
                          />
                        </Box>
                      )}
                    </Sticky>
                  </Box>
                </StickyContainer>
              </>
            ) : (
              <>
                <Header
                  name={project.name}
                  category={project.tags[0]}
                  date={project.date}
                />

                <Text
                  width="100%"
                  maxWidth="40ch"
                  fontFamily="body"
                  dangerouslySetInnerHTML={{ __html: project.description }}
                />
                <Box
                  css={{
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridRowGap: 24,
                  }}
                >
                  {project.gallery}
                </Box>
              </>
            )
          }
        </Media>
      </FadingContent>

      <TransitionLink
        to={project.next.projectUrl}
        exit={exitTransition}
        entry={entryTransition}
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <SlidingHeader pose={transitionStatus}>
          <FadingNextProjectHeading pose={transitionStatus}>
            <NextProjectHeading />
          </FadingNextProjectHeading>
          <Header
            name={project.next.name}
            category={project.next.tags[0]}
            date={project.next.date}
          />
        </SlidingHeader>
      </TransitionLink>
    </>
  )
}

const Project = ({ data, pageContext: context }) => {
  const project = {
    name: data.markdownRemark.frontmatter.title,
    tags: data.markdownRemark.frontmatter.tags,
    date: new Date(data.markdownRemark.frontmatter.date).toLocaleDateString(
      'fr-FR',
      {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
    ),
    description: data.markdownRemark.html,
    image: data.markdownRemark.frontmatter.featuredimage.childImageSharp.fluid,
    gallery: data.markdownRemark.frontmatter.images.map(image => {
      const imageIndex = data.childImages.edges.findIndex(x => {
        const path = image.split('/')
        const name = path
          .slice(path.length - 1)
          .join()
          .split('.')[0]

        return x.node.name === name
      })
      return (
        imageIndex > -1 && (
          <Img
            key={imageIndex}
            fluid={
              data.childImages.edges[imageIndex].node.childImageSharp.fluid
            }
          />
        )
      )
    }),
    next: {
      name: data.next.frontmatter.title,
      tags: data.next.frontmatter.tags,
      date: new Date(data.next.frontmatter.date).toLocaleDateString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      projectUrl: `/projets${context.nextSlug}`,
    },
  }
  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <ProjectInner transitionStatus={transitionStatus} project={project} />
      )}
    </TransitionState>
  )
}

export default Project

export const query = graphql`
  query($slug: String!, $nextSlug: String!) {
    next: markdownRemark(fields: { slug: { eq: $nextSlug } }) {
      html
      frontmatter {
        title
        tags
        date
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        images
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        date
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        images
      }
    }
    childImages: allFile(
      filter: { sourceInstanceName: { eq: "data" }, extension: { eq: "jpg" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 400, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
