import React from 'react'
import { Box, Text } from 'rebass'
import styled from 'styled-components'

const Rule = styled.hr`
  height: 1px;
  margin: 0;
  margin-bottom: 2px;
  border: none;
  background-color: ${({ theme }) => theme.colors.indigoLightest};
`
const NextProjectHeading = () => (
  <Box mt={4} mb={2}>
    <Rule />
    <Text as="span" fontFamily="sans" fontSize={0} color="indigoDarker">
      Projet suivant
    </Text>
  </Box>
)

export default NextProjectHeading
